/* You can add global styles to this file, and also import other style files */
.form-control{
    color: black !important;
}
.menu-title{
    color: black !important;
}
body{
    color: black !important;
}

table {
    color: black !important;
}
